<template>
  <div class="user-management">
    <div class="nav-header margin-top-bottom-twenty box-shadow-light-grey border-radius-6">
      <el-menu class='border-radius-6' :default-active="activeSubMenu" router mode="horizontal">
        <el-menu-item
          class="font-eighteen margin-right-23"
          :index="item.path"
          v-for="item in userMenu"
          :key="item.path"
        >
          <i class="iconfont" :class="item.icon"></i>
          <span slot="title">{{ item.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import { userMenu } from '@/assets/js/nav.js'
import { mapActions } from 'vuex'
export default {
  name: 'UserManagement',
  data () {
    return {
      activeSubMenu: this.$route.meta.submenu,
      userMenu
    }
  },
  watch: {
    $route: {
      handler (newV) {
        this.activeSubMenu = newV.meta.submenu || newV.path
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
      addRouterHistory: 'addRouterHistory'
    }),
    handleMenuClick (path) {
      const menuData = this.userMenu.find(item => item.path === path)
      this.addRouterHistory({
        params: {},
        title: menuData.title,
        path: menuData.path
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-menu-item.is-active {
  border-right: 0;
}
.nav-header{
  background:#fff;
  box-sizing: border-box;
  padding:0 5px;
}
.user-management {
  /deep/ .el-menu-item,
  .el-submenu__title {
    height: 34px;
  }
  /deep/ .el-menu {
    height: 52px !important;
    display: flex;
    align-items: center;
    .el-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
